.container {
  cursor: pointer;
}

.input {
  font-size: 0;
  &::-webkit-file-upload-button {
    display: none;
  }
}

.upload {
  min-height: 102px;
  &:before {
    display: none !important;
  }
  &:not(:global(.multiple)) {
    :global(.ant-upload-select) {
      margin: 0 !important;
      &:global(::before) {
        display: none !important;
      }
    }
    :global(.ant-upload-list-item-container) {
      &:global(::before) {
        display: none !important;
      }
      margin: 0 !important;
      + :global(.ant-upload-select) {
        display: none !important;
      }
    }
  }
}
