.container {
  flex-direction: row;

  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .sideBar {
    &:global(.ant-layout-sider) {
      position: fixed;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 200;
      display: flex;
      flex-direction: column;
      box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    }

    .logoContainer {
      padding: 16px 8px;
      text-align: center;

      .logo {
        max-width: 160px;
        transition: all ease 0.1s;
      }
    }

    .menu {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 0;
    }

    .sideBarCollapseButton {
      background-color: #fff;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      cursor: pointer;
      border-top: 1px solid #d9d9d9;
    }
  }

  .layout {
    --sidebar-width-collapse: 80px;

    .userInfoHeader {
      line-height: 0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 10;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgb(95 101 108 / 10%);
      margin-bottom: 4px;
      padding: 0 24px;
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.8;
        z-index: -1;
      }

      .titleBox {
        display: flex;
        align-items: center;

        .titleString {
          margin: 0;
        }
      }

      .actionContainer {
        .actionBox {
          padding: 0px 16px;
        }
      }
    }

    .stickyHeader {
      position: fixed;
      top: 0;
      right: 0;
      left: var(--sidebar-width);
      transition: all ease 0.25s;
      width: unset;
      background-color: #fff;

      &.stickyHeaderWithSmallSidebar {
        left: var(--sidebar-width-collapse);
      }
      &.stickyHeaderWithoutSidebar {
        left: 0;
      }
    }

    .contentWrapper {
      min-height: calc(100vh - 68px);
      transition: margin-left ease 0.25s;
      margin-top: 67px;
      margin-left: var(--sidebar-width);

      &.contentWrapperWithoutHeader {
        margin-top: unset;
      }

      &.contentWrapperWithSmallSidebar {
        margin-left: var(--sidebar-width-collapse);
      }

      &.contentWrapperWithoutSidebar {
        margin-left: unset;
      }
    }
  }
}
