.userInfoHeader {
  line-height: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgb(95 101 108 / 10%);
  margin-bottom: 4px;
  padding: 0 24px;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.8;
    z-index: -1;
  }

  &:global(.ant-layout-header) {
    padding-inline: 20px;
  }

  .titleBox {
    display: flex;
    align-items: center;
    gap: 4px;

    .titleString {
      margin: 0;
    }
  }

  .actionContainer {
    .actionBox {
      padding: 0px 16px;
    }
  }
}
