.contentLayout {
  --spacing-layout: 20px;
  margin-top: var(--spacing-layout);
  margin-bottom: 68px; // space for end of page

  .body,
  .actions,
  .footer {
    margin-top: var(--spacing-layout);
  }

  .box {
    background-color: #fff;
    padding: 16px;
    margin: auto var(--spacing-layout);
    margin-bottom: var(--spacing-layout);
    border-radius: var(--border-radius-base);
    box-shadow: 0 3px 6px 0 rgb(100 100 100 / 10%);
  }
  .contentHeader {
    @extend .box;
  }
  .body {
    @extend .box;
  }
  .contentHeaderWrapper {
    display: flex;
  }
  .leftHeader {
    flex: 1;
    :global(.ant-form-item) {
      margin-bottom: 0;
    }
  }
  .rightHeader {
    padding-left: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: --spacing-layout;
  }

  &.contentLayoutTransparent {
    .body {
      background-color: transparent;
      padding: 0;
      margin: auto var(--spacing-layout);
      margin-bottom: var(--spacing-layout);
      border-radius: 0;
      box-shadow: none;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 16px;
  }

  .body {
    &.hasHeader {
      padding: 0;
      .bodyContent {
        padding: 0 16px 16px;
        display: none;
        &.open {
          display: block;
        }
      }
    }
    .bodyHeader {
      display: flex;
      align-items: center;
      padding: 16px;
      cursor: pointer;
      .title {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        flex: 1;
      }
    }
    .divider {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 16px;
    }
  }
}
