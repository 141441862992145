.quill {
  :global(.ql-editor) {
    min-height: 300px;
    max-height: calc(100vh - 100px);
  }
  :global(.ql-toolbar) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  :global(.ql-container) {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
