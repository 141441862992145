.loginLayout {
  display: flex;
  flex-direction: row;
  height: 100vh;

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .leftSection {
    @extend .section;
    background-color: #f2f7ff;
    .loginBanner {
      max-width: 100%;
      width: 64%;
    }
  }
  .rightSection {
    @extend .section;
    background-color: #fff;
  }
}

.loginContainer {
  width: 386px;

  .alert {
    margin-bottom: 16px;
  }

  .titleBox {
    span {
      font-size: 32px;
      margin-left: 8px;
    }
  }
  .loginButton {
    margin-top: 10px;
  }
}
