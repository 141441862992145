.selectReadOnly {
  &:global(
      .ant-select-disabled.css-dev-only-do-not-override-14wwjjs.ant-select:not(.ant-select-customize-input)
        .ant-select-selector
    ) {
    color: rgba(0, 0, 0, 0.88);
    background-color: #ffffff;
    cursor: auto;
  }
}
