/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  color: #1b1b1c;
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  --border-radius-base: 4px;
}

.cssScrollBar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
  }
}

.error {
  font-size: 0.9em;
  color: #ff4d4f;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ant-form-item-label {
  font-weight: 600;
}
.rdw-editor-toolbar {
  border: 1px solid #d9d9d9 !important;
  border-bottom-color: #f0f0f0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.rdw-editor-main {
  border: 1px solid #d9d9d9;
  border-top: none;
  padding: 12px;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.ant-picker,
.ant-input-number,
.ant-input-number-group-wrapper {
  width: 100% !important;
}

.tox-notifications-container {
  display: none;
}
.tox-promotion {
  display: none;
}
